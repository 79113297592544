import { useMemo, useState, Fragment } from 'react'
import { Badge } from '@/components/ui/badge'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { Button } from '@/components/ui/button'

interface ParsedConfig {
  type: 'config'
  values: Record<string, string | number>
}

interface ParsedHealth {
  type: 'health'
  values: Record<string, string | number>
}

type ParsedDiagnostic = ParsedConfig | ParsedHealth

function DiagnosticDataCell({ data }: { data: string }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const parsedData = useMemo(() => {
    try {
      // Parse the raw string into key-value pairs
      const pairs = data.split(',').map(pair => {
        const [key, value] = pair.split(':').map(s => s.trim())
        return [key, value]
      })

      // Determine type based on content
      const isHealth = pairs.some(([key]) => key.includes('modem'))

      if (isHealth) {
        return {
          type: 'health',
          values: Object.fromEntries(pairs)
        } as ParsedHealth
      }

      return {
        type: 'config',
        values: Object.fromEntries(pairs)
      } as ParsedConfig

    } catch (err) {
      return null
    }
  }, [data])

  if (!parsedData) {
    return (
      <div className="text-sm text-slate-600 whitespace-pre-wrap break-all">
        {data}
      </div>
    )
  }

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="sm"
          className="h-6 w-6 p-0"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? (
            <ChevronDown className="h-4 w-4" />
          ) : (
            <ChevronRight className="h-4 w-4" />
          )}
        </Button>
        <Badge variant={parsedData.type === 'config' ? 'secondary' : 'default'}>
          {parsedData.type === 'config' ? 'Configuration' : 'System Health'}
        </Badge>
        {!isExpanded && (
          <div className="text-sm text-slate-600 truncate">
            {data.slice(0, 100)}{data.length > 100 ? '...' : ''}
          </div>
        )}
      </div>
      
      {isExpanded && (
        <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-0.5 text-sm pl-8">
          {Object.entries(parsedData.values).map(([key, value]) => (
            <Fragment key={key}>
              <div className="text-slate-500 whitespace-nowrap">{key.replace(/_/g, ' ')}:</div>
              <div className="font-medium">{typeof value === 'number' ? 
                value.toLocaleString(undefined, { maximumFractionDigits: 2 }) 
                : value}
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default DiagnosticDataCell 