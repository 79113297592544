import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringParam, useQueryParam } from 'use-query-params'
import { AlertCircle } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Alert, AlertDescription } from '../ui/alert'
import DeviceImeiMultiSelect from '../v2/selects/DeviceImeiMultiSelect'
import useAdminTriggerDiagnostic from '@/hooks/useAdminTriggerDiagnostic'
import { failureToastClassNames, successfulToastClassNames } from '@/utils/toastHelpers'

interface Props {
  deviceMap: Map<string, string>
}

export default function TriggerDiagnosticsModal({ deviceMap }: Props) {
  const { t } = useTranslation(['admin'])
  const [id, setId] = useQueryParam('id', StringParam)
  const [selectedImeis, setSelectedImeis] = useState<string[]>([])
  const { toast } = useToast()
  const { triggerDiagnostic, isTriggering } = useAdminTriggerDiagnostic()

  const isOpen = id === 'new-diagnostic'

  const handleClose = () => {
    setId(null)
    setSelectedImeis([])
  }

  const handleTriggerDiagnostics = async () => {
    if (selectedImeis.length === 0) {
      toast({
        title: 'Please select at least one device',
        className: failureToastClassNames,
      })
      return
    }

    try {
      await Promise.all(selectedImeis.map(imei => triggerDiagnostic(imei)))
      toast({
        title: `Diagnostic requests sent to ${selectedImeis.length} device${selectedImeis.length > 1 ? 's' : ''}`,
        className: successfulToastClassNames,
      })
      handleClose()
    } catch (err) {
      toast({
        title: 'Failed to request diagnostics',
        className: failureToastClassNames,
      })
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={() => handleClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('Request Device Diagnostics')}</DialogTitle>
          <DialogDescription>
            Select devices to request diagnostics from
          </DialogDescription>
        </DialogHeader>

        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            It may take up to 10 minutes for the diagnostic data to appear, as we need to wait for the next metadata uplink from the device.
          </AlertDescription>
        </Alert>

        <div className="my-4">
          <DeviceImeiMultiSelect
            selectedImeis={selectedImeis}
            onSelect={setSelectedImeis}
            deviceMap={deviceMap}
          />
        </div>

        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleTriggerDiagnostics}
            disabled={isTriggering || selectedImeis.length === 0}
          >
            Request Diagnostics
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
} 