import { Check } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from '@/components/ui/command'
import { cn } from '@/lib/utils'

interface Props {
    selectedImeis: string[]
    onSelect: (imeis: string[]) => void
    deviceMap: Map<string, string>
}

export default function DeviceImeiMultiSelect({ selectedImeis, onSelect, deviceMap }: Props) {
    const { t } = useTranslation(['admin'])

    const toggleImei = (imei: string) => {
        if (selectedImeis.includes(imei)) {
            onSelect(selectedImeis.filter(i => i !== imei))
        } else {
            onSelect([...selectedImeis, imei])
        }
    }

    return (
        <Command className="border rounded-md">
            <CommandInput placeholder={t('device_select.placeholder')} onValueChange={(search) => {
                // Custom filtering handled here
                const searchLower = search.toLowerCase()
                return Array.from(deviceMap.entries()).filter(([imei, name]) =>
                    imei.toLowerCase().includes(searchLower) ||
                    name.toLowerCase().includes(searchLower)
                )
            }} />
            <CommandEmpty>{t('device_select.no_devices_found')}</CommandEmpty>
            <CommandGroup className="max-h-64 overflow-auto">
                {Array.from(deviceMap.entries()).map(([imei, name]) => (
                    <CommandItem
                        key={imei}
                        value={`${imei} ${name}`} // Include both values in the searchable value
                        data-cmdk-input-wrapper={`${imei} ${name}`}
                        onSelect={() => toggleImei(imei)}
                    >
                        <Check
                            className={cn(
                                'mr-2 h-4 w-4',
                                selectedImeis.includes(imei) ? 'opacity-100' : 'opacity-0'
                            )}
                        />
                        <div className="flex flex-col">
                            <span className="font-medium">{name}</span>
                            <span className="text-xs text-slate-500">{imei}</span>
                        </div>
                    </CommandItem>
                ))}
            </CommandGroup>
        </Command>
    )
} 