import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getDiagnostics } from '@/data/admin/diagnostic'

const DEFAULT_REFETCH_INTERVAL =
  import.meta.env.VITE_ADMIN_DIAGNOSTICS_POLLING_INTERVAL || 30000

function useAdminDiagnostics(
  imei?: string | null,
  take?: number,
  page?: number,
  from?: Date,
  to?: Date,
  orgId?: string | null,
) {
  const abortController = new AbortController()
  const queryClient = useQueryClient()

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['admin-diagnostics', take, page, imei, from, to, orgId],
    queryFn: async () => {
      const result = await getDiagnostics(
        abortController.signal,
        imei,
        take,
        page,
        from,
        to,
        orgId,
      )
      // After each successful fetch, invalidate pending diagnostics
      queryClient.invalidateQueries({ queryKey: ['admin-pending-diagnostics'] })
      return result
    },
    placeholderData: keepPreviousData,
    refetchInterval: Number(DEFAULT_REFETCH_INTERVAL),
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { data, isLoading, refetch, isRefetching }
}

export default useAdminDiagnostics 