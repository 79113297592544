import * as Sentry from '@sentry/react'

import { axiosClient } from '@/lib/axios'

const BASE_URI = '/admin/diagnostic'

export interface Diagnostic {
  id: number
  data: string
  deviceId: number
  imei: string
  createdAt: string
}

export interface PaginatedDiagnostics {
  diagnostics: Diagnostic[]
  totalCount: number
}

export const getDiagnostics = async (
  abortSignal: AbortSignal,
  imei?: string | null,
  take = 20,
  page = 0,
  from?: Date,
  to?: Date,
  orgId?: string | null,
) => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      take: take.toString(),
      ...(typeof imei === 'string' && { imei }),
      ...(from && { from: from.toISOString() }),
      ...(to && { to: to.toISOString() }),
      ...(orgId && { orgId }),
    })

    const res = await axiosClient.get<PaginatedDiagnostics>(
      `${BASE_URI}?${params.toString()}`,
      {
        signal: abortSignal,
      },
    )
    return res.data
  } catch (err) {
    Sentry.captureException(err)
    throw err
  }
} 