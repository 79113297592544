import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { getPaginatedMetadataDownlinks } from '@/data/admin/metadataDownlink'

function useAdminPendingDiagnostics() {
  const abortController = new AbortController()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['admin-pending-diagnostics'],
    queryFn: async () => {
      const data = await getPaginatedMetadataDownlinks(
        abortController.signal,
        undefined,
        undefined,
        100,
        0,
        undefined,
        undefined,
        false,
      )
      return data.downlinks.filter(
        d => d.uplinkDiagnostic === 1 && !d.isDownlinkedToDevice
      )
    },
  })

  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return { pendingRequests: data || [], isLoading, refetch }
}

export default useAdminPendingDiagnostics 