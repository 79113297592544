import { useTranslation } from 'react-i18next'
import { RefreshCw } from 'lucide-react'
import { useMemo } from 'react'
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type Row,
} from '@tanstack/react-table'
import {
  DateTimeParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import { useQueryClient } from '@tanstack/react-query'

import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import useAdminDiagnostics from '@/hooks/useAdminDiagnostics'
import type { Diagnostic } from '@/data/admin/diagnostic'
import DeviceImeiAutocomplete from '@/components/v2/selects/DeviceImeiAutocomplete'
import OrganizationNameAutocomplete from '@/components/admin/OrganizationNameAutocomplete'
import TableDateSelect from '@/components/v2/selects/TableDateSelect'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'
import PaginatedTable from '@/components/v2/PaginatedTable'
import useAdminTriggerDiagnostic from '@/hooks/useAdminTriggerDiagnostic'
import { useToast } from '@/components/ui/use-toast'
import { failureToastClassNames, successfulToastClassNames } from '@/utils/toastHelpers'
import TriggerDiagnosticsModal from '@/components/admin/TriggerDiagnosticsModal'
import PendingDiagnostics from '@/components/admin/PendingDiagnostics'
import DiagnosticDataCell from '@/components/admin/DiagnosticDataCell'
import useAdminDevices from '@/hooks/useAdminDevices'

export default function DiagnosticsPage() {
  const queryClient = useQueryClient()
  const { t } = useTranslation(['admin'])
  const [searchText, setSearchText] = useQueryParam('imei', StringParam)
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))
  const [pageSize, setPageSize] = useQueryParam(
    'pageSize',
    withDefault(NumberParam, 20),
  )
  const [orgId, setOrgId] = useQueryParam('orgId', StringParam)
  const [from] = useQueryParam('from', DateTimeParam)
  const [to] = useQueryParam('to', DateTimeParam)
  const [id, setId] = useQueryParam('id', StringParam)

  const { data, isLoading, refetch, isRefetching } = useAdminDiagnostics(
    searchText,
    pageSize,
    page,
    from ?? undefined,
    to ?? undefined,
    orgId,
  )

  // Fetch all devices to get names
  const { data: deviceData } = useAdminDevices()
  const deviceMap = useMemo(() => 
    new Map(deviceData?.devices?.map(d => [d.imei, d.name]) || []),
    [deviceData?.devices]
  )

  const columns = useMemo<ColumnDef<Diagnostic>[]>(() => {
    return [
      {
        accessorKey: 'createdAt',
        header: t('diagnostics.timestamp'),
        cell: ({ row }: { row: Row<Diagnostic> }) => {
          const { createdAt } = row.original
          return (
            <span className="whitespace-nowrap">
              {dateWithTimeInIsoFormat(new Date(createdAt))}
            </span>
          )
        },
      },
      {
        accessorKey: 'imei',
        header: t('diagnostics.imei'),
        cell: ({ row }) => {
          const { imei } = row.original
          const deviceName = deviceMap.get(imei)
          return (
            <div className="flex flex-col">
              <span>{imei}</span>
              {deviceName && (
                <span className="text-sm text-slate-500">{deviceName}</span>
              )}
            </div>
          )
        }
      },
      {
        accessorKey: 'data',
        header: t('diagnostics.data'),
        cell: ({ row }) => {
          const { data } = row.original
          return <DiagnosticDataCell data={data} />
        },
      },
    ]
  }, [t, deviceMap])

  const table = useReactTable({
    data: data ? data.diagnostics || [] : [],
    columns,
    pageCount: data?.totalCount ? Math.ceil(data.totalCount / pageSize) : -1,
    state: {
      pagination: {
        pageSize: pageSize,
        pageIndex: page,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater !== 'function') return

      const nextState = updater({ pageIndex: page, pageSize: pageSize })

      setPage(nextState.pageIndex)
      setPageSize(nextState.pageSize)
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  const { toast } = useToast()
  const { triggerDiagnostic, isTriggering } = useAdminTriggerDiagnostic()

  const handleRequestDiagnostics = async () => {
    if (!searchText) {
      toast({
        title: 'Please select a device first',
        className: failureToastClassNames,
      })
      return
    }

    try {
      await triggerDiagnostic(searchText)
      toast({
        title: 'Diagnostic request sent',
        className: successfulToastClassNames,
      })
    } catch (err) {
      toast({
        title: 'Failed to request diagnostics',
        className: failureToastClassNames,
      })
    }
  }

  const handleOpenDiagnosticsModal = () => {
    setId('new-diagnostic')
  }

  const handleRefresh = async () => {
    await refetch()
    // After diagnostics are refetched, refetch pending requests
    queryClient.invalidateQueries({ queryKey: ['admin-pending-diagnostics'] })
  }

  return (
    <div className="px-4">
      <div className="relative my-8">
        <h2 className="text-3xl">{t('Diagnostics')}</h2>
        <div className="absolute right-0 top-0">
          <Button
            variant="outline"
            onClick={handleRefresh}
            disabled={isRefetching}
          >
            <RefreshCw
              className={cn('h-4 w-4', isRefetching && 'animate-spin')}
            />
            <span className="hidden pl-2 text-base md:block">Refresh</span>
          </Button>
        </div>
      </div>

      <PendingDiagnostics deviceMap={deviceMap} />

      <div className="my-4 flex flex-wrap gap-4 md:flex-nowrap">
        <div className="min-w-full sm:min-w-[200px]">
          <OrganizationNameAutocomplete
            onSelect={(val) => setOrgId(val)}
            value={orgId}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <DeviceImeiAutocomplete
            orgId={orgId}
            imei={searchText || ''}
            onSelect={(val) => setSearchText(val)}
          />
        </div>
        <div className="min-w-full sm:min-w-[200px]">
          <TableDateSelect />
        </div>
        <div className="flex flex-1 justify-end">
          <Button
            type="button"
            variant="outline"
            size="icon"
            onClick={handleOpenDiagnosticsModal}
            title={t('Request Device Diagnostics')}
          >
            +
          </Button>
        </div>
      </div>

      <PaginatedTable<Diagnostic> table={table} isLoading={isLoading} />
      <TriggerDiagnosticsModal deviceMap={deviceMap} />
    </div>
  )
} 