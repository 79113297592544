import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { CreateUpdateMetadataDownlink } from '@/components/admin/AddEditMetadataDownlinkModal'
import useAdminMetadataDownlink from './useAdminMetadataDownlink'

function useAdminTriggerDiagnostic() {
  const { onCreate } = useAdminMetadataDownlink(null)
  const queryClient = useQueryClient()

  const { mutateAsync: triggerDiagnostic, isPending } = useMutation({
    mutationFn: async (imei: string) => {
      const data: CreateUpdateMetadataDownlink = {
        imei,
        samples: -1,
        dataUplinkPeriod: -1,
        metadataUplinkPeriod: -1,
        fotaUplinkPeriod: -1,
        setCycleTime: -1,
        uplinkDiagnostic: 1,
      }
      return onCreate(data)
    },
    onSuccess: () => {
      // Refetch pending diagnostics after successful trigger
      queryClient.invalidateQueries({ queryKey: ['admin-pending-diagnostics'] })
    }
  })

  return {
    triggerDiagnostic,
    isTriggering: isPending,
  }
}

export default useAdminTriggerDiagnostic 