import { Badge } from '@/components/ui/badge'
import useAdminPendingDiagnostics from '@/hooks/useAdminPendingDiagnostics'
import { dateWithTimeInIsoFormat } from '@/utils/dateHelpers'
import { Clock } from 'lucide-react'

interface Props {
  deviceMap: Map<string, string>
}

export default function PendingDiagnostics({ deviceMap }: Props) {
  const { pendingRequests, isLoading } = useAdminPendingDiagnostics()

  if (isLoading || pendingRequests.length === 0) return null

  return (
    <div className="mb-4 rounded-lg border border-slate-200 bg-slate-50 p-3">
      <div className="flex items-center gap-2 mb-2">
        <Badge variant="secondary" className="h-5 px-2 text-xs">Pending</Badge>
        <h3 className="text-sm font-medium">
          {pendingRequests.length} Diagnostic Request{pendingRequests.length > 1 ? 's' : ''}
        </h3>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-1.5">
        {pendingRequests.map((request) => (
          <div 
            key={request.id}
            className="flex items-center justify-between rounded bg-white px-2 py-1.5"
          >
            <div className="flex items-center gap-2 min-w-0 flex-1">
              {deviceMap.get(request.imei) ? (
                <div className="leading-tight truncate">
                  <div className="font-medium text-sm truncate">{deviceMap.get(request.imei)}</div>
                  <div className="text-xs text-slate-500 truncate">{request.imei}</div>
                </div>
              ) : (
                <span className="text-sm truncate">{request.imei}</span>
              )}
            </div>
            <div className="flex items-center gap-1.5 text-xs text-slate-500 shrink-0">
              <Clock className="h-3 w-3" />
              <span>
                {dateWithTimeInIsoFormat(new Date(request.createdAt))}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
} 